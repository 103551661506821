<template>
  <div class="skeleton_chart">
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="single_column"></div>
    <div class="container_rows">
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
      <div class="single_row"></div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
