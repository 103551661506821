var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.item)?_c('div',[_c('SparksAreaCharts',{attrs:{"dir":"ltr","SectionRoute":_vm.item.route,"ChartColor":_vm.color.colors.first,"ChartTitle":"","ChartName":_vm.item.title,"ChartLabels":[
      _vm.item.title,
      _vm.item.title,
      _vm.item.title,
      _vm.item.title,
      _vm.item.title,
      _vm.item.title,
    ],"ChartData":[
      (100 / 4).toFixed(2),
      (90 / 1.5).toFixed(2),
      (100 / 1.8).toFixed(2),
      (70 / 2).toFixed(2),
      (89 / 1.2).toFixed(2),
      100,
    ]},scopedSlots:_vm._u([{key:"chart_title",fn:function(){return [_c('div',{staticClass:"content d-flex flex-column"},[_c('h4',{staticClass:"title_text mb-0",style:({ color: _vm.color.colors.first })},[_vm._v(" "+_vm._s(_vm.item.title)+" ")]),_c('p',{staticClass:"chart_val mb-0",style:({ color: _vm.color.colors.second })},[_c('bdi',[_vm._v(" "+_vm._s(_vm.$t(`top_charts.${_vm.item.key}`))+" ")]),_c('bdi',[_vm._v(" "+_vm._s(Math.floor(_vm.item.count))+" ")])])])]},proxy:true}],null,false,835268512)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }