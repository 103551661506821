import Vue from "vue";
import VueRouter from "vue-router";

// ============================== Start Views ==============================
// Home
import Home from "../views/Home.vue";

// ********** Children
import HomePage from "../views/children/HomePage.vue";

// ========== Start Profiles ==========

// ========== End Profiles ==========

// ============================== START:: CRUDS ==============================

// ========== Start:: Static Screens ==========
import StaticScreens from "../views/children/StaticScreens/Home.vue";
// ========== End:: Static Screens ==========

// ========== Start:: Reference ==========
import Reference from "../Reference/Reference.vue";
// ========== End:: Reference ==========

// ********** Auth
import Login from "../views/Auth/Login.vue";

// ********** NotFound
import NotFound from "../views/NotFound.vue";
// ============================== End Views ==============================

// Vuex store
import store from "../store/index";
let router = null;
window.rootRouter = router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    // Main
    {
      path: "/",
      component: Home,
      children: [
        {
          path: "/",
          name: "HomePage",
          component: HomePage,
        },
        // ============================== Start CRUDS ==============================
        // ******* Start Countries *******
        {
          path: "/countries",
          component: () => import("../views/children/CRUDS/Countries/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllCountries",
              component: () =>
                import("../views/children/CRUDS/Countries/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "CountryShow",
              component: () =>
                import("../views/children/CRUDS/Countries/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "CountryEdit",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "CountryAdd",
              component: () =>
                import("../views/children/CRUDS/Countries/Form.vue"),
            },
          ],
        },
        // ******* End Countries *******
        // ******* Start Services *******
        {
          path: "/services",
          component: () => import("@/views/children/CRUDS/Services/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllServices",
              component: () =>
                import("@/views/children/CRUDS/Services/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "serviceShow",
              component: () =>
                import("@/views/children/CRUDS/Services/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "serviceEdit",
              component: () =>
                import("@/views/children/CRUDS/Services/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "serviceAdd",
              component: () =>
                import("@/views/children/CRUDS/Services/Form.vue"),
            },
          ],
        },
        // ******* End Services *******
        // ******* Start Sliders *******
        {
          path: "/sliders",
          component: () => import("@/views/children/CRUDS/Sliders/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllSliders",
              component: () =>
                import("@/views/children/CRUDS/Sliders/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "sliderShow",
              component: () =>
                import("@/views/children/CRUDS/Sliders/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "sliderEdit",
              component: () =>
                import("@/views/children/CRUDS/Sliders/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "sliderAdd",
              component: () =>
                import("@/views/children/CRUDS/Sliders/Form.vue"),
            },
          ],
        },
        // ******* End Sliders *******
        // ******* Start About *******
        {
          path: "/abouts",
          component: () => import("@/views/children/CRUDS/Abouts/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAbouts",
              component: () =>
                import("@/views/children/CRUDS/Abouts/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "aboutShow",
              component: () =>
                import("@/views/children/CRUDS/Abouts/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "aboutEdit",
              component: () =>
                import("@/views/children/CRUDS/Abouts/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "aboutAdd",
              component: () =>
                import("@/views/children/CRUDS/Abouts/Form.vue"),
            },
          ],
        },
        // ******* End About *******
        // ******* Start Equipment *******
        {
          path: "/equipments",
          component: () => import("@/views/children/CRUDS/Equipments/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllEquipments",
              component: () =>
                import("@/views/children/CRUDS/Equipments/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "equipmentShow",
              component: () => import("@/views/children/CRUDS/Equipments/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "equipmentEdit",
              component: () => import("@/views/children/CRUDS/Equipments/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "equipmentAdd",
              component: () => import("@/views/children/CRUDS/Equipments/Form.vue"),
            },
          ],
        },
        // ******* End Equipment *******
        // ******* Start Partners *******
        {
          path: "/partners",
          component: () => import("@/views/children/CRUDS/Partners/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPartners",
              component: () =>
                import("@/views/children/CRUDS/Partners/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "partnerShow",
              component: () => import("@/views/children/CRUDS/Partners/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "partnerEdit",
              component: () => import("@/views/children/CRUDS/Partners/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "partnerAdd",
              component: () => import("@/views/children/CRUDS/Partners/Form.vue"),
            },
          ],
        },
        // ******* End Partners *******
        // ******* Start StaticPages *******
        {
          path: "/staticpages",
          component: () => import("@/views/children/CRUDS/StaticPages/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllStaticPages",
              component: () =>
                import("@/views/children/CRUDS/StaticPages/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "staticPageShow",
              component: () => import("@/views/children/CRUDS/StaticPages/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "staticPageEdit",
              component: () => import("@/views/children/CRUDS/StaticPages/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "staticPageAdd",
              component: () => import("@/views/children/CRUDS/StaticPages/Form.vue"),
            },
          ],
        },
        // ******* End StaticPages *******
        // ******* Start Staff *******
        {
          path: "/staff",
          component: () => import("@/views/children/CRUDS/Staff/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllStaff",
              component: () =>
                import("@/views/children/CRUDS/Staff/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "staffShow",
              component: () => import("@/views/children/CRUDS/Staff/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "staffEdit",
              component: () => import("@/views/children/CRUDS/Staff/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "staffAdd",
              component: () => import("@/views/children/CRUDS/Staff/Form.vue"),
            },
          ],
        },
        // ******* End Staff *******
        // ******* Start Projects *******
        {
          path: "/projects",
          component: () => import("@/views/children/CRUDS/Projects/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllProjects",
              component: () =>
                import("@/views/children/CRUDS/Projects/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "projectShow",
              component: () => import("@/views/children/CRUDS/Projects/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "projectEdit",
              component: () => import("@/views/children/CRUDS/Projects/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "projectAdd",
              component: () => import("@/views/children/CRUDS/Projects/Form.vue"),
            },
          ],
        },
        // ******* End Projects *******

        // ******* Start ContactMessages *******
        {
          path: "/contact-us",
          component: () => import("@/views/children/CRUDS/ContactMessages/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllContactMessages",
              component: () =>
                import("@/views/children/CRUDS/ContactMessages/ShowAll.vue"),
            },
          ],
        },
        // ******* End ContactMessages *******

        // ******* Start notifications *******
        {
          path: "/notifications",
          component: () =>
            import("@/views/children/CRUDS/notifications/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-notifications",
              component: () =>
                import("@/views/children/CRUDS/notifications/ShowAll.vue"),
            },
            // Show Single

            {
              path: "add",
              name: "add-notification",
              component: () =>
                import("@/views/children/CRUDS/notifications/Form.vue"),
            },
          ],
        },
        // ******* End notifications *******
        // ******* Start  Admins *******
        {
          path: "/admins",
          component: () => import("@/views/children/CRUDS/Admins/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllAdmins",
              component: () =>
                import("@/views/children/CRUDS/Admins/ShowAll.vue"),
            },
            // Show Single
            {
              path: "show/:id",
              name: "adminShow",
              component: () => import("@/views/children/CRUDS/Admins/Show.vue"),
              props: true,
            },
            // Edit
            {
              path: "edit/:id",
              name: "adminEdit",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
              props: true,
            },
            // Add
            {
              path: "add",
              name: "adminAdd",
              component: () => import("@/views/children/CRUDS/Admins/Form.vue"),
            },
          ],
        },
        // ******* End  Admins *******

        // ******* Start  Permission *******
        {
          path: "/permissions",
          component: () =>
            import("@/views/children/CRUDS/Permissions/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "AllPermissions",
              component: () =>
                import("@/views/children/CRUDS/Permissions/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "EditPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "AddPermission",
              component: () =>
                import("@/views/children/CRUDS/Permissions/Form.vue"),
            },
          ],
        },
        // ******* End  Permission *******
        // ******* Start  permissions-labels *******
        {
          path: "/permission-labels",
          component: () =>
            import("@/views/children/CRUDS/PermissionLabels/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-labels",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-label",
              component: () =>
                import("@/views/children/CRUDS/PermissionLabels/Form.vue"),
            },
          ],
        },
        // ******* End  roles *******
        // ******* Start  roles *******
        {
          path: "/roles",
          component: () => import("@/views/children/CRUDS/Roles/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-roles",
              component: () =>
                import("@/views/children/CRUDS/Roles/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-role",
              component: () => import("@/views/children/CRUDS/Roles/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******
        // ******* Start  permissions-categories *******
        {
          path: "/permission-categories",
          component: () =>
            import("@/views/children/CRUDS/PermissionCategory/Home.vue"),
          children: [
            // Show All
            {
              path: "show-all",
              name: "all-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/ShowAll.vue"),
            },
            // Show Single

            // Edit
            {
              path: "edit/:id",
              name: "edit-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
              props: true,
            },
            // Edit
            {
              path: "add",
              name: "add-permission-category",
              component: () =>
                import("@/views/children/CRUDS/PermissionCategory/Form.vue"),
            },
          ],
        },
        // ******* End  permissions-categories *******

        // ******* Start Live  Chat*******
        {
          path: "/chats/:id?",
          component: () => import("@/views/children/Chat/Index.vue"),
          name: "chats",
          props: true,
        },
        // ******* End  Live  Chat *******

        // ============================== End CRUDS ==============================

        // ========== Start StaticScreens ==========
        {
          path: "/static-screens",
          name: StaticScreens,
          component: StaticScreens,
        },
        // ========== End StaticScreens ==========

        // ========== Start Settings ==========
        {
          path: "/settings",
          component: () => import("../views/children/Settings/Home.vue"),
          children: [
            {
              path: "",
              name: "mainSettings",
              component: () => import("../views/children/Settings/General.vue"),
            },
            {
              path: "social",
              name: "socialSettings",
              component: () => import("../views/children/Settings/Social.vue"),
            },
            {
              path: "sms",
              name: "sms",
              component: () =>
                import("../views/children/Settings/SmsServices.vue"),
            },
            {
              path: "order-setting",
              name: "order-setting",
              component: () => import("../views/children/Settings/Orders.vue"),
            },
            {
              path: "app-links",
              name: "app-links",
              component: () =>
                import("../views/children/Settings/AppLinks.vue"),
            },
          ],
        },
        // ========== End Settings ==========

        // ========== Start Profiles ==========
        {
          path: "/profile",

          component: () => import("@/views/children/adminProfile/Home.vue"),
          children: [
            {
              path: "show",
              name: "showProfile",
              component: () => import("@/views/children/adminProfile/Show.vue"),
            },
            {
              path: "edit",
              name: "editProfile",
              component: () => import("@/views/children/adminProfile/Form.vue"),
            },
            {
              path: "password",
              name: "changePassword",
              component: () =>
                import("@/views/children/adminProfile/ChangePassword.vue"),
            },
          ],
        },
        // ========== End Profiles ==========

        // ========== Start Reference==========
        {
          path: "/reference",
          name: "Reference",
          component: Reference,
        },
        // ========== End Reference ==========

        // ========== Start Chat ==========
        {
          path: "/financial_reports",
          name: "FinancialReports",
          component: () =>
            import("../views/children/FinancialReports/Home.vue"),
        },
        // ========== Start Chat ==========

        // ========== End Chat ==========

        // ========== Start DriversTracking ==========
        // {
        //   path: '/drivers_tracking',
        //   name: 'DriversTracking',
        //   component: DriversTracking,
        // },
        // ========== End DriversTracking ==========
      ],
    },

    {
      path: "/permission-required",
      name: "permission-required",
      component: () => import("../views/PermissionRequired.vue"),
    },

    //  =========== Start Redirects ===============
    {
      path: "/countries",

      redirect: () => {
        return { path: "/countries/show-all" };
      },
    },
    {
      path: "/about",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    {
      path: "/about/add",

      redirect: () => {
        return { path: "/about/show-all" };
      },
    },
    {
      path: "/terms/add",

      redirect: () => {
        return { path: "/terms/show-all" };
      },
    },
    {
      path: "/privacy/add",

      redirect: () => {
        return { path: "/privacy/show-all" };
      },
    },
    //  =========== End  Redirects ===============
    // Auth
    {
      path: "/login",
      name: "Login",
      component: Login,
    },

    // NotFound
    { path: "/:notFound(.*)", name: "NotFound", component: NotFound },
  ],
});

const TOKEN = store.getters["auth_module/currentUser"].token;

router.beforeEach((to, _2, next) => {
  if (to.name !== "Login" && !TOKEN) {
    next({ name: "Login" });
  } else if (to.name == "Login" && TOKEN) {
    next("/");
  } else {
    next();
  }
});

Vue.use(VueRouter);
export default router;
