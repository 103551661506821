<template>
  <div class="loader_general">
    <div class="image_loader">
      <img src="../../assets/media/images/Others/bot.png" alt="" />
    </div>
    <span></span>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
