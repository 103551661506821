export default class Permission {
  setControls(path) {
    let status
    if (localStorage.getItem('Elmuthannaplus_Dash_User_Type') != 'supper_admin') {
      if (localStorage.getItem('elmuthannaplus_controls')) {
        let controls = JSON.parse(localStorage.getItem('elmuthannaplus_controls'))
        const found = controls.find((control) => control.url == path)

        if (found) {
          status = false
        } else {
          status = true
        }
      }
    } else {
      status = false
    }
    return status
  }
  allowed(path) {
    let status
    if (localStorage.getItem('Elmuthannaplus_Dash_User_Type') != 'supper_admin') {
      if (localStorage.getItem('elmuthannaplus_permissions')) {
        let controls = JSON.parse(localStorage.getItem('elmuthannaplus_permissions'))

        const found = controls.find((control) => control.url == path)
        if (found) {
          status = false
        } else {
          status = true
        }
      }
    } else {
      status = false
    }
    return status
  }
}
