<template>
  <div class="skeleton_chart">
    <div class="container_circles">
      <div class="single_circle">
        <div class="before_bg"></div>
        <div class="after_bg"></div>
      </div>
      <div class="single_circle">
        <div class="before_bg"></div>
        <div class="after_bg"></div>
      </div>
      <div class="single_circle">
        <div class="before_bg"></div>
        <div class="after_bg"></div>
      </div>
      <div class="single_circle">
        <div class="before_bg"></div>
        <div class="after_bg"></div>
      </div>
    </div>
    <ul>
      <li>
        <p></p>
        <span></span>
      </li>
      <li>
        <p></p>
        <span></span>
      </li>
      <li>
        <p></p>
        <span></span>
      </li>
      
    </ul>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
